<template>
  <div>
    <van-field
      v-model="exkey"
      center
      clearable
      label="对接码"
      placeholder="请输入专属项目的对接码"
    >
      <template #button>
        <van-button
          size="small"
          color="linear-gradient(to right, #4bb0ff, #6149f6)"
          @click="onclick()"
          >对接</van-button
        >
      </template>
    </van-field>
  </div>
</template>

<script>
export default {
  name: "pushExlist",
  data() {
    return { exkey: "" };
  },
  methods: {
    async onclick() {
      let ret = await this.$api.fn_user_exclusive_push("", this.exkey,"1");
      ret = ret.data;
      if (ret.message === "ok") {
        this.$toast("对接成功");
      } else {
        this.$toast(ret.message+",请检查改专属你是否已经对接过");
      }
    },
  },
};
</script>

<style scoped>
</style>